<template>
    <div class="active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal">
                    <div class="icon"><icon-lock-bold /></div>
                    <div class="title" v-html="$t('forgot_password_success.title')"></div>
				    <div class="description">{{ $t('forgot_password_success.description_1') }} <strong>{{ emailSend }}</strong> {{ $t('forgot_password_success.description_2') }}</div>
                </div>

                <div class="submit-footer">
                    <div class="submit-form">
                        <button id="buttonSubmit" class="button-submit" @click="redirect()">
                            <div class="text">{{$t('forgot_password_success.button-action')}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import IconLockBold from '../Icons/LockBold'
export default {
	data(){
		return {
            emailSend: ''
		}
	},
    components: {
        IconLockBold
    },
    mounted(){
        if(this.$route.query.email){
            this.emailSend = this.$route.query.email
        }else{
            this.emailSend = ''
        }
    },
	methods: {
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>